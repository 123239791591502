.navbar {
  background-color: #2d3494;
}

.navbar a {
  text-decoration: none;
  color: white;
  font-size: x-large;
}

.brand-name {
  margin: auto;
  color: white;
  font-family: "Lora", serif;
}

.navbar .brand-name:hover {
  transform: scale(1.2);
}
/* -------------------------------------------------- */
.home-page-carousel {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
}

/* Card */

.product-card {
  border-radius: 20px;
  padding: 10px;
  padding-bottom: unset;
  max-width: fit-content;
  background-image: url(./assets/Images/carousel_background.png);
  background-size: cover;
}

.card-container {
  display: flex;
  position: relative;
  width: 100%;
}

.column {
  flex-wrap: nowrap;
  padding: 10px;
  box-sizing: "border-box";
}

/* column-1 */

.brand-column {
  width: 10%;
}

.brand-column img {
  height: auto;
  width: 100%;
}

/* column-2 */

.info-column {
  position: relative;
  width: 25%;
  display: flex;
  flex-direction: column;
  text-align: right;
}

.product-concise-info,
.product-name {
  padding: 4px;
  font-family: "Lora", serif;
  font-weight: 800;
  font-style: italic;
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  color: #116b1e;
}

.product-name {
  margin-top: 5rem;
}

.info-column button {
  align-self: flex-end;
  background-color: #086315;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  width: 10rem;
}

.info-column button:hover {
  background-color: #074c11;
}

/* column-3 */

.image-column {
  width: auto;
  padding: unset;
  padding-left: 20px;
}

.image-column img {
  height: 100%;
  width: 100%;
}

/* This for transition on image */

/* .image-column img {
  border-radius: 25px;
  background-color: #ececec;
  transform: perspective(900px) scale(0.7);
  box-shadow: 0px 20px 100px #555;
  transition: 0.5s ease all;
  background-image: url(./assets/Images/middle_image_background.png);
  border-top-left-radius: 25%;
  border-top-right-radius: 25%;
}

.image-column:hover img {
  cursor: pointer;
  transform: rotate(0deg) scale(1) translateY(10px);
} */

/* column-4 */

.small-cards-column {
  display: flex;
  flex-direction: column;
  width: 15%;
  justify-content: center;
  align-items: center;
}

.small-product-card {
  cursor: pointer;
  border-radius: 4px;
  height: 4rem;
  width: 4rem;
  margin-top: 15px;
  box-shadow: 3px 3px 3px 3px rgb(156, 155, 155);
}

.small-product-card img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

/* -----------------some more elements------------ */

.footer {
  display: flex;
  background-color: #ffcb05;
  width: 100%;
  min-height: 10rem;
  justify-content: space-around;
  align-items: center;
}

.footer .icon {
  font-size: xx-large;
  color: #28a03a;
}

.footer .icon:hover {
  box-sizing: border-box;
  border: 2px solid #28a03a;
  border-radius: 5px;
}
/* ---------------------------------- */

@media only screen and (max-width: 1200px) {
  .brand-column {
    width: 40%;
    margin: auto;
  }

  .product-name{
    margin-top: unset;
  }

  .product-card {
    max-width: unset;
  }

  .card-container {
    all: unset;
  }

  .info-column {
    all: unset;
  }

  .image-column {
    width: 70%;
    margin: auto;
  }

  .small-cards-column {
    display: flex;
    flex-direction: row;
    width: unset;
    justify-content: center;
    align-items: center;
  }

  .small-product-card {
    height: 4rem;
    width: 4rem;
    margin-left: 1rem;
  }
}
